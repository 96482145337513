<template>
    <v-container>
        <base-material-card icon="mdi-account-multiple" :title="`Sucursales de ${cliente.razon_social}`" class="px-5 py-3">
            <v-row>
                <v-col cols="12">
                    <v-btn @click="$router.push({name: 'Cliente'})">Regresar</v-btn>
                    <v-btn color="primary" @click="$router.push({name: 'ClienteSucursalCreate'})">Nuevo</v-btn>
                    <v-text-field v-model="pagination.search" append-icon="mdi-magnify" label="Búsqueda" single-line hide-details @keyup="load"></v-text-field>
                </v-col>

                <v-col cols="12">
                    <v-data-table :loading="loading" :headers="headers" :items="sucursales" item-key="id" hide-default-footer disable-pagination class="elevation-1">
                        <template v-slot:item.accciones="{item}">
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn fab icon x-small v-on="on" @click="$router.push({name: 'ClienteSucursalEdit', params: {sucursal: item.id, cliente: $route.params.cliente}})">
                                        <v-icon>far fa-edit</v-icon>
                                    </v-btn>
                                </template>
                                <span>Editar</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn fab icon x-small v-on="on" @click="qr(item)">
                                        <v-icon>fas fa-qrcode</v-icon>
                                    </v-btn>
                                </template>
                                <span>Código QR</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn fab icon x-small v-on="on" @click="toggle(item)">
                                        <v-icon v-text="item.habilitado ? 'fas fa-toggle-on' : 'fas fa-toggle-off'"></v-icon>
                                    </v-btn>
                                </template>
                                <span v-text="item.habilitado ? 'Deshabilitar' : 'Habilitar'"></span>
                            </v-tooltip>
                        </template>
                    </v-data-table>
                </v-col>
                <v-col col="12">
                    <!-- <v-pagination v-model="pagination.currentPage" class="my-4" :length="pagination.lastPage" @input="load" :total-visible="10" color="primary"></v-pagination> -->
                </v-col>
            </v-row>
        </base-material-card>
    </v-container>
</template>
<script>
    export default {
        data: () => ({
            headers: [
                { text: 'Nombre', value: 'nombre', sortable: false },
                { text: 'Vendedor', value: 'vendedor.razon_social', sortable: false },
                { text: 'Lista de precios', value: 'lista.nombre', sortable: false },
                { text: 'Acciones', value: 'accciones', sortable: false },
            ],
            sucursales: [],
            pagination: {
                perPage: 10,
                lastPage: 1,
                currentPage: 1,
                search: null
            },
            loading: false,
            cliente: {}
        }),
        mounted() {
            this.load()
            this.loadCliente()
        },
        methods: {
            load() {
                this.loading = true
                this.$http.get(`/clientes/${this.$route.params.cliente}/sucursales`, {
                    params: {
                        page: this.pagination.currentPage,
                        search: this.pagination.search
                    }
                }).then(response => {
                    this.sucursales = response.data
                    // this.pagination.lastPage = response.data.last_page
                    // this.pagination.currentPage = response.data.current_page
                }).finally(() => {
                    this.loading = false
                })
            },
            loadCliente() {
                this.$http.get(`/clientes/${this.$route.params.cliente}`).then(response => {
                    this.cliente = response.data
                })
            },
            qr(sucursal) {
                window.open(`/sucursales/${sucursal.id}/qr`, 'sucursal_qr', 'location=no,titlebar=0')
            },
            toggle(item) {
                this.$http.put(`/sucursales/${item.id}/toggle`).then(response => {
                    this.load()
                })
            }
        }
    }
</script>